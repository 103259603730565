import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "python-custom-sort-function",
  "author": "tzookb",
  "type": "post",
  "date": "2020-07-21T13:50:08.000Z",
  "url": "/python-custom-sort-function",
  "featuredImage": "./thumb.jpg",
  "desc": "Python python-custom-sort-function",
  "categories": ["reminder", "python"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This tiny post is for me, as I was had two chanlleges I did that required "custom sort function".
It was super hard to "google" it as things were different in python 2 and I'm using python 3 now.`}</p>
    <p>{`The bottom line it does the same but just weird sytax.`}</p>
    <pre><code parentName="pre" {...{}}>{`# python 3
from typing import List
from functools import cmp_to_key


weirdList = [
    {
        "a": 1,
        "b": 3,
    },
    {
        "a": 1,
        "b": 2,
    },
    {
        "a": 2,
        "b": 1,
    },
]
def owncomp(a, b):
    if a["a"] > b["a"]:
        return 1
    elif a["a"] < b["a"]:
        return -1
    
    if a["b"] > b["b"]:
        return 1
    else:
        return -1

rs = sorted(weirdList, key=cmp_to_key(owncomp))
print(rs)
`}</code></pre>
    <h2>{`Simpler Sort Option (only changing key)`}</h2>
    <pre><code parentName="pre" {...{}}>{`intervals.sort(key=lambda x: x["a"])
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`class LargerNumKey(str):
    def __lt__(x, y):
        print(x, y, x+y > y+x)
        return x+y > y+x

largest_num = sorted(map(str, nums), key=LargerNumKey)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      